import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/games-icon/home-icon1.png";
import CasinoIcon from "./../../assets/images/games-icon/Livecasino.png";
import InplayIcon from "./../../assets/images/games-icon/In-play.png";
import CricketIcon from "./../../assets/images/games-icon/Cricket.png";
import SoccerIcon from "./../../assets/images/games-icon/Soccer.png";
import TennisIcon from "./../../assets/images/games-icon/Tennis.png";
import AviatorIcon from "./../../assets/images/games-icon/Aviator.png";
import EzugiIcon from "./../../assets/images/games-icon/Ezugi.png";
import EvolutionIcon from "./../../assets/images/games-icon/Evolution.png";
import PromotionIcon from "./../../assets/images/games-icon/Promotion.png";
import GolfIcon from "./../../assets/images/games-icon/golficon.png";
import RugbyIcon from "./../../assets/images/games-icon/rugby-icon.png";
import BoxingIcon from "./../../assets/images/games-icon/boxing-icon.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import EsportsIcon from "./../../assets/images/games-icon/esports-icon.png";
import VolleyballIcon from "./../../assets/images/games-icon/volleyball-icon.png";
import CyclingIcon from "./../../assets/images/games-icon/cycling-icon.png";
import SnookerIcon from "./../../assets/images/games-icon/snooker-icon.png";
import BaseballIcon from "./../../assets/images/games-icon/baseball-icon.png";
import NetballIcon from "./../../assets/images/games-icon/netball-icon.png";
import BasketballIcon from "./../../assets/images/games-icon/basketball-icon.png";
import IceHockeyIcon from "./../../assets/images/games-icon/icehockey-icon.png";
import HandballIcon from "./../../assets/images/games-icon/handball-icon.png";
import DartsIcon from "./../../assets/images/games-icon/dart-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import VirtualIcon from "./../../assets/images/games-icon/cricket-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/political-icon.png";
import SpaceIcon from "./../../assets/images/games-icon/space.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  const getMatchLength = (id) => {
    let value = sportsData?.find(({ SportId }) => SportId == id)?.values
      ?.length;
    return value || 0;
  };

  return (
    <>
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Inplay", { state: { selectedTab: "1" } });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay Icon" />
                  <span>In-play</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/worldcasino");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>World Casino</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            <h6>Sports</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Cricket", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={CricketIcon} alt="Cricket Icon" />
                  <span>Cricket</span>
                  <div className="sport-event-count badge">
                    {
                      sportsData?.find(
                        ({ sportname }) => sportname === "Cricket"
                      )?.values?.length
                    }
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Soccer", { state: { selectedTab: "3" } });
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer Icon" />
                  <span>Soccer</span>
                  <div className="sport-event-count badge">
                    {
                      sportsData?.find(
                        ({ sportname }) => sportname === "Soccer"
                      )?.values?.length
                    }
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Tennis", { state: { selectedTab: "4" } });
                  }}
                >
                  <img src={TennisIcon} alt=" Icon" />
                  <span>Tennis</span>
                  <div className="sport-event-count badge">
                    {
                      sportsData?.find(
                        ({ sportname }) => sportname === "Tennis"
                      )?.values?.length
                    }
                  </div>
                </div>
              </li>
              {/* <li>
                <a href="/sports/Cricket">
                  <img src={GolfIcon} alt="Golf Icon" />
                  <span>Golf</span>
                </a>
              </li>
              <li>
                <a href="/sports/Cricket">
                  <img src={RugbyIcon} alt="Rugby Icon" />
                  <span>Rugby</span>
                </a>
              </li>
              <li>
                <a href="/sports/Cricket">
                  <img src={BoxingIcon} alt="Boxing Icon" />
                  <span>Boxing</span>
                </a>
              </li> */}
              <li>
                <a href="/sports/Snooker">
                  <img src={SnookerIcon} alt="Snooker Icon" />
                  <span>Snooker</span>
                </a>
              </li>
              <li>
                <a href="/sports/Horse Racing">
                  <img src={horseracingIcon} alt="horseracing Icon" />
                  <span>Horse Racing</span>
                </a>
              </li>
              {/* <li>
                <a href="/sports/Cricket">
                  <img src={EsportsIcon} alt="Esports Icon" />
                  <span>Esports</span>
                </a>
              </li> */}
              {/* <li>
                <a href="/sports/Cricket">
                  <img src={VolleyballIcon} alt="Volleyball Icon" />
                  <span>Volleyball</span>
                </a>
              </li>
              <li>
                <a href="/sports/Cricket">
                  <img src={CyclingIcon} alt="Cycling Icon" />
                  <span>Cycling</span>
                </a>
              </li> */}
              {/* <li>
                <a href="/sports/Cricket">
                  <img src={BaseballIcon} alt="Baseball Icon" />
                  <span>Baseball</span>
                </a>
              </li>
              <li>
                <a href="/sports/Cricket">
                  <img src={NetballIcon} alt="Netball Icon" />
                  <span>Netball</span>
                </a>
              </li>
              <li>
                <a href="/sports/Cricket">
                  <img src={BasketballIcon} alt="Basketball Icon" />
                  <span>Basketball</span>
                </a>
              </li>
              <li>
                <a href="/sports/Cricket">
                  <img src={IceHockeyIcon} alt="Ice Hockey Icon" />
                  <span>Ice Hockey</span>
                </a>
              </li>
              <li>
                <a href="/sports/Cricket">
                  <img src={HandballIcon} alt="Handball Icon" />
                  <span>Handball</span>
                </a>
              </li>
              <li>
                <a href="/sports/Cricket">
                  <img src={DartsIcon} alt="Darts Icon" />
                  <span>Darts</span>
                </a>
              </li> */}
              <li>
                <a href="/sports/Greyhound Racing">
                  <img src={GreyhoundIcon} alt="Greyhound Racing Icon" />
                  <span>Greyhound Racing</span>
                </a>
              </li>
              <li>
                <a href="/sports/Virtual Cricket">
                  <img src={VirtualIcon} alt="Virtual Cricket Icon" />
                  <span>Virtual Cricket</span>
                </a>
              </li>
              <li>
                <a href="/sports/Politics">
                  <img src={PoliticsIcon} alt="Politics Icon" />
                  <span>Politics</span>
                </a>
              </li>
              <li>
                <a href="/sports/SPACE">
                  <img src={SpaceIcon} alt="Space Icon" />
                  <span>SPACE</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
//
