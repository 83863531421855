import React from "react";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { useSelector } from "react-redux";

const BeforeContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <div className="container">
            <h2 className="sectionTitle">Contact Us</h2>
            <div className="payment-info">
              <div class="social-icons contact-social">
                <ul>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      {/* <span className="notification-count">2</span> */}
                      <img src={Whatsapp} alt="Whatsapp Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails.TELEGRAM != "" ? appDetails.TELEGRAM : "#"
                      }
                      target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Telegram} alt="Telegram Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails.INSTATGRAM != ""
                          ? appDetails.INSTATGRAM
                          : "#"
                      }
                      target={appDetails.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Instagram} alt="Instagram Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails.FACEBOOK != "" ? appDetails.FACEBOOK : "#"
                      }
                      target={appDetails.FACEBOOK != "" ? "_blank" : ""}
                    >
                      <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default BeforeContactUs;
